



















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import CreateBoard from "@/components/workspace/CreateBoard.vue";
import UserNotifications from "@/components/user/profile/UserNotifications.vue";
import WorkspaceBoardItem from "@/components/navigation/WorkspaceBoardItem.vue";
import {Role} from "@/typescript/types";
import Roles from '@/roles';
import sortBy from "lodash/sortBy";
import {Container, Draggable} from "vue-smooth-dnd";
import pick from "lodash/pick";
import {UpdateWorkspaceBoards, WorkspaceQuery} from "@/graphql/workspace";
import UserStatus from "@/components/user/UserStatus.vue";
import UserStatusText from "@/components/user/UserStatusText.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";
import MemberListItem from "@/components/navigation/MemberListItem.vue";
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";
import WorkspaceSwitcher from "@/components/navigation/WorkspaceSwitcher.vue";
import {
  GetAllWorkspaceNotificationForUser, WorkspaceNotificationCreatedSubscription

} from "@/graphql/WorkspaceNotification";
import VerifyUser from "@/components/auth/VerifyUser.vue";
import WorkspaceAvatar from "@/components/workspace/WorkspaceAvatar.vue";
import WorkspaceManagerIndex from "@/components/workspace/manager/WorkspaceManagerIndex.vue";
import Plans from "@/components/subscription/Plans.vue";
import WorkspaceSettingsIndex from "@/components/settings/workspace/WorkspaceSettingsIndex.vue";
// import Paywall from "@/components/subscription/Paywall.vue";
import Paywall from '../subscription/Paywall.vue';
//import {isBefore} from "date-fns";
//import Encryption from "@/encryption";
//import {WorkspaceNotificationCreatedSubscription} from "@/graphql/WorkspaceNotification";
//import PusherService from "@/PusherService";

import UserType from "@/components/auth/register/UserType.vue";

@Component({
  components: {
    WorkspaceAvatar,
    WorkspaceSwitcher,
    MemberListItem,
    UserStatusText,
    WorkspaceBoardItem,
    CreateBoard,
    UserStatus,
    UserNotifications,
    Container,
    Draggable
  },
  apollo: {
    getAllWorkspaceNotifications: {
      query: GetAllWorkspaceNotificationForUser,
      subscribeToMore: {
        document: WorkspaceNotificationCreatedSubscription,
        variables() {
          return {
            workspace_id: this.$store.state.workspace.id,
          }
        },
        updateQuery(_previous, {subscriptionData}) {
          if(subscriptionData.data.workspaceNotificationCreated != null) {
            let boardNotification = subscriptionData.data.workspaceNotificationCreated;
            this.fireNotificationEvent(boardNotification, 1);

          }

          //this.$store.commit('add_board_notification', boardNotification)


        },
      },
      variables() {
        return {
          id: this.$store.state.workspace.id
        }
      },
      result(result) {
        result.data.getAllWorkspaceNotifications.forEach((item: any) => {
          this.fireNotificationEvent(item, 0);
        })

      },

    }
  }

})
export default class WorkspaceSidebar extends Vue {
  workspaceLoading: boolean = false;
  expandMoreRooms: boolean = false;
  currentlyReOrderingRooms: boolean = false;
  isVerifyModalOpened: boolean = false;
  dynamicTooltip: string = "";
  hideTooltip: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  get planList() {
    return this.$store.state.plans;
  }

  get currentSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get userType() {
    return this.$store.state.me.account_type;
  }

  get isPrimaryOwner() {
    return (this.workspace.my_role === Role.PrimaryOwner) ? true : false;
  }

  get newUser() {
    let userCreationDate = new Date(this.me.created_at);
    let comparisonDate = new Date('2024-02-22');
    return userCreationDate > comparisonDate;
  }

  get accountAge() {
    const userCreationDate = new Date(this.me.created_at); 
    const currentDate = new Date(); 
    const differenceInTime = currentDate.getTime() - userCreationDate.getTime(); 
    const differenceInDays = differenceInTime / (1000 * 3600 * 24); 
    return differenceInDays ;
  }

  get currentPlan() {
    if(this.workspace.currentSubscription !== null) {
      return this.workspace.currentSubscription.plan.title
    }
    else { return null }
  }




  mounted() {
    
    document.title = "Pembio | Rooms";
  }

  destroyed() {
    document.title = "Pembio";
  }

  updateTooltip(boardName: string) {
    this.dynamicTooltip = boardName;
  }

  

  // startOnboarding() {
  //   this.$store.commit('set_user_onboarding_number', 1);
  // }

  get upgradeIndicator() {
    return !this.workspace.currentSubscription;
  }

  get trialEnded()  {
    return this.$store.state.trial_ended
  }

  get canAddMember() {
    if(this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess) {
      return true;
    }else{
      if(this.filterDeletedWorkspaceMember.length >= this.planList[0].planAccess.max_user){
        return false
      }
      return true;
    }
  }

  get canAddNewRoom() {
    if(this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.max_board > this.myRooms.length) {
      return true;
    }else if(this.planList[0].planAccess.max_board > this.myRooms.length) {
      return true;
    }
    return false;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get myRole(): Role {
    return this.$store.state.workspace.my_role;
  }

  get hasUnreadNotification() {
    let unReadNotification = this.$store.state.me.notifications.data.filter((noti: any) => {
      return noti.read_at == null;
    });

    if(unReadNotification.length > 0){
      this.$events.fire('new-notification', unReadNotification);
      return true;
    }

    return false;

  }

  roleName(role: string) {
    return Roles.formateRole(role);
  }

  get hasPinnedMessages(){
    if(this.$store.state.workspace.pinnedMessages) {
      return this.$store.state.workspace.pinnedMessages.length >= 1;
    }
    return false;
  }

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+g': this.openCreateBoardModal,
      
    }
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get workspaceAvatar() {
    if(this.$store.state.workspace.avatar) {
     
      return this.$store.state.workspace.avatar;
    } else {
      return null;
    }
  }

  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }

  get currentlyOnboarding() {
    return !!(this.$store.state.currently_onboarding || this.$store.state.currently_onboarding_in_room);
  }

  get showGuestClaim() {
    return !!(this.onGuestAccount && !this.currentlyOnboarding);
  }

  get dateOfToday() {
    return new Date();
  }

  get singleRoomWorkspace() {
    return this.workspace.boards.length === 1;
  }

  get singleMemberWorkspace() {
    return this.workspace.members.length === 1;
  }

  get myRooms() {
    let boards = sortBy(this.workspace.boards, ['order']);
    return boards;
  }

  // get onboardingStep() {
  //   return this.$store.state.user_onboarding_number;
  // }

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }

  allowAccessFromPlanLimit(index: number) {
    let boardWithIndexCount = index + 1;
    let planLimit = 0;
    if(this.workspace.currentSubscription && this.workspace.currentSubscription.hasAccess && this.workspace.currentSubscription.plan.planAccess.max_board) {
      planLimit = this.workspace.currentSubscription.plan.planAccess.max_board;
    } else {
      planLimit = this.planList[0].planAccess.max_board;
    }
    if(boardWithIndexCount > planLimit) {
      return true;
    } else {
      return false;
    }

    /*
    if(this.workspace.currentSubscription) {
      if(this.workspace.currentSubscription.hasAccess && this.workspace.currentSubscription.plan.planAccess.max_board < boards.length) {
        return boards.splice(boards.length - this.workspace.currentSubscription.plan.planAccess.max_board, this.workspace.currentSubscription.plan.planAccess.max_board);
      }else if(this.workspace.currentSubscription.hasAccess && this.workspace.currentSubscription.plan.planAccess.max_board > boards.length){
        return boards;
      }else{
        return boards.slice(0, this.planList[0].planAccess.max_board);
      }
    }
    return boards.slice(0, this.planList[0].planAccess.max_board);

     */
  }

  get filterDeletedWorkspaceMember() {
    if(this.workspace.members) {
      return this.workspace.members.filter((member: any) => {
        return member.user.deleted_at === null;
      })
    }
    return null;
  }

  get showWorkspaceLinks() {
    return this.$route.name === 'workspace-unread-notification' || this.$route.name === 'files-manager' || this.$route.name === 'workspace-pinned-messages';
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  openResources() {
    if(this.$route.name !== 'premium-tease') {
      this.$router.push({ name: 'premium-tease'});
    } 

  }

  openFiles() {
    if(this.$route.name !== 'my-files') {
      this.$router.push({ name: 'my-files'});
    } 
  }

  openPinned() {
    if(this.$route.name !== 'pinned') {
      this.$router.push({ name: 'pinned'});
    } 
  }

  roomOnboardingCancelled() {
    this.$router.push({ name: 'workspace-layout' });
    this.$gtag.event('pmb_room_onboarding_cancelled');
  }

  sidebarReRoute() {
    this.$emit('mobile-menu-toggle', true);
  }

  routeToLobby() {
    this.$router.push({ name: 'workspace-layout' });
  }

  getGhostParent() {
    return document.body;
  }

  getChildPayload(index: number) {
    if (this.myRooms) {
      return this.myRooms[index]
    }
  }

  onDragStart(_dragResult: any): void {
    
    //this.$emit('on-drag-start', dragResult);
  }

  onDragStop(_dragResult: any): void {
   
    //this.$emit('on-drag-stop', dragResult)
  }

  onDrop(dropResult: any): void {
    if (this.myRooms) {
      this.applyDrag(dropResult);
    }
  }

  openStatusModal() {
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }

  get board() {
    return this.$store.state.board
  }

  openWorkspaceManager() {
    this.$buefy.modal.open({
      component: WorkspaceManagerIndex,
      // @ts-ignore
      customClass: 'workspace_manager_modal',
      parent: this,
    });
  }

  openInvoices() {
    if(this.isAdminOfWorkspace) {
      this.$buefy.modal.open({
        component: WorkspaceSettingsIndex,
        width: '480px',
        // @ts-ignore
        customClass: 'is-paddingless',
        parent: this,
      });
    }
  }

  openUserProfile() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      props: {
        redirect: 'feedback'
      },
      width: '700px',
      parent: this,
    });
  }

  openFeedback() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      props: {
        redirect: 'feedback'
      },
      width: '700px',
      parent: this,
    });
  }

  openUserInviteModal() {
    this.$buefy.modal.open({
      component: CreateWorkspaceInviteModal,
      props: {
        workspaceId: this.workspace!.id,
        workspacePid: this.workspace!.pid,
      },
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      width: 500,
    });
  }

  applyDrag(dragResult: any): void {
    let {removedIndex, addedIndex, payload} = dragResult
    if (removedIndex === null && addedIndex === null || !payload) return;


    if (!this.myRooms) {
      return;
    }

    let realIndex = this.getRealDraggedIndex(payload);

    this.myRooms.splice(realIndex, 1);
    this.myRooms.splice(addedIndex, 0, payload)
    this.reindexOrder(this.myRooms);

  }

  getRealDraggedIndex(payloadObj: any): number {
    let locallyBoard = payloadObj;

    return this.myRooms.findIndex((board: any) => {
      return board.id === locallyBoard.id
    })
  }

  reindexOrder(boards: any): void {
    let updateBoards = new Array();

    boards.forEach((board: any, index: number) => {
      let clone = Object.assign({}, board)
      clone.order = index;

      updateBoards.push(clone)
    });
    this.workspace.boards = updateBoards;
   
    this.updateBoards(updateBoards);
  }

  updateBoards(items: any) {
    let updatedItems = new Array();

    items.forEach((item: any) => {
      updatedItems.push(pick(item, [
        'id',
        'order',
      ]));
    })

    return this.$apollo.mutate({
      mutation: UpdateWorkspaceBoards,
      variables: {
        id: this.workspace.id,
        boards: updatedItems,
      },
      refetchQueries: [{ query: WorkspaceQuery, variables:{ pid: this.workspace.pid}}]
    }).then((response) => {
      return response;
    }).catch((_error) => {

    });
  }

  openCreateBoardModal() {
    this.$buefy.modal.open({
      component: CreateBoard,
      width: '860px',
      // @ts-ignore
      customClass: 'room_create_modal is-paddingless',
      parent: this,
    });
  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_sidebar');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: !this.$store.state.currently_on_verification_code_step,
      parent: this,
    });
  }

  fireNotificationEvent(data: any, isSubs: boolean) {
    this.$events.fire('board-notification', {'data': data, 'isSubs': isSubs});
  }

  openPlanSelection() {
    this.$buefy.modal.open({
      component: Plans,
      width: '480px',
      canCancel: true,
      parent: this,
    });
  }

  openUserTypeModal() {
    this.$buefy.modal.open({
      component: UserType,
      //@ts-ignore
      customClass: 'user_type_selection',
      canCancel: true,
      parent: this,
      width: '550px',
    });
  }

 
  removeActivities() {
    this.$store.commit('remove_onboarding_northstar_id', null);
  }
 

  @Watch('$store.state.active_workspace_pid', { immediate: true })
  onWorkspaceChange() {
    this.workspaceLoading = true;
    setTimeout(() => {
      this.workspaceLoading = false;
    }, 1500);
  }

}
